ol.lst-kix_99sc5y773xk6-2.start {
  counter-reset: lst-ctn-kix_99sc5y773xk6-2 0;
}
.lst-kix_99sc5y773xk6-0 > li {
  counter-increment: lst-ctn-kix_99sc5y773xk6-0;
}
.lst-kix_qwfn645kyhn7-3 > li:before {
  content: '\0025cf  ';
}
.lst-kix_qwfn645kyhn7-2 > li:before {
  content: '\0025a0  ';
}
ol.lst-kix_99sc5y773xk6-8.start {
  counter-reset: lst-ctn-kix_99sc5y773xk6-8 0;
}
ol.lst-kix_99sc5y773xk6-5.start {
  counter-reset: lst-ctn-kix_99sc5y773xk6-5 0;
}
/* .lst-kix_99sc5y773xk6-6 > li:before {
  content: '' counter(lst-ctn-kix_99sc5y773xk6-0, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-1, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-2, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-3, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-4, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-5, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-6, decimal) '. ';
}
.lst-kix_99sc5y773xk6-8 > li:before {
  content: '' counter(lst-ctn-kix_99sc5y773xk6-0, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-1, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-2, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-3, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-4, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-5, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-6, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-7, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-8, decimal) '. ';
} */
.lst-kix_qwfn645kyhn7-1 > li:before {
  content: '\0025cb  ';
}
.lst-kix_qwfn645kyhn7-0 > li:before {
  content: '\0025cf  ';
}
/* .lst-kix_99sc5y773xk6-7 > li:before {
  content: '' counter(lst-ctn-kix_99sc5y773xk6-0, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-1, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-2, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-3, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-4, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-5, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-6, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-7, decimal) '. ';
} */
.lst-kix_99sc5y773xk6-4 > li {
  counter-increment: lst-ctn-kix_99sc5y773xk6-4;
}
.lst-kix_99sc5y773xk6-2 > li:before {
  content: '' counter(lst-ctn-kix_99sc5y773xk6-0, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-1, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-2, decimal) '. ';
}
.lst-kix_99sc5y773xk6-4 > li:before {
  content: '' counter(lst-ctn-kix_99sc5y773xk6-0, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-1, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-2, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-3, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-4, decimal) '. ';
}
ol.lst-kix_99sc5y773xk6-6.start {
  counter-reset: lst-ctn-kix_99sc5y773xk6-6 0;
}
/* .lst-kix_99sc5y773xk6-1 > li {
  counter-increment: lst-ctn-kix_99sc5y773xk6-1;
} */
/* .lst-kix_99sc5y773xk6-1 > li:before {
  content: '' counter(lst-ctn-kix_99sc5y773xk6-0, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-1, decimal) '. ';
}
.lst-kix_99sc5y773xk6-5 > li:before {
  content: '' counter(lst-ctn-kix_99sc5y773xk6-0, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-1, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-2, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-3, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-4, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-5, decimal) '. ';
} */

li {
  font-weight: bold;
}
li.li-bullet-1:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_qwfn645kyhn7-5 > li:before {
  content: '\0025a0  ';
}
.lst-kix_qwfn645kyhn7-4 > li:before {
  content: '\0025cb  ';
}
.lst-kix_99sc5y773xk6-3 > li:before {
  content: '' counter(lst-ctn-kix_99sc5y773xk6-0, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-1, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-2, decimal) '.'
    counter(lst-ctn-kix_99sc5y773xk6-3, decimal) '. ';
}
.lst-kix_99sc5y773xk6-6 > li {
  counter-increment: lst-ctn-kix_99sc5y773xk6-6;
}
.lst-kix_qwfn645kyhn7-6 > li:before {
  content: '\0025cf  ';
}
.lst-kix_qwfn645kyhn7-7 > li:before {
  content: '\0025cb  ';
}
.lst-kix_99sc5y773xk6-0 > li:before {
  content: '' counter(lst-ctn-kix_99sc5y773xk6-0, decimal) '. ';
}
.lst-kix_qwfn645kyhn7-8 > li:before {
  content: '\0025a0  ';
}
ul.lst-kix_qwfn645kyhn7-8 {
  list-style-type: none;
}
ul.lst-kix_qwfn645kyhn7-7 {
  list-style-type: none;
}
.lst-kix_evjada3nolsj-2 > li:before {
  content: '\0025a0  ';
}
ul.lst-kix_evjada3nolsj-2 {
  list-style-type: none;
}
ul.lst-kix_qwfn645kyhn7-6 {
  list-style-type: none;
}
ol.lst-kix_99sc5y773xk6-6 {
  list-style-type: none;
}
ul.lst-kix_evjada3nolsj-1 {
  list-style-type: none;
}
ul.lst-kix_qwfn645kyhn7-5 {
  list-style-type: none;
}
ol.lst-kix_99sc5y773xk6-5 {
  list-style-type: none;
}
ol.lst-kix_99sc5y773xk6-7.start {
  counter-reset: lst-ctn-kix_99sc5y773xk6-7 0;
}
ul.lst-kix_evjada3nolsj-4 {
  list-style-type: none;
}
ul.lst-kix_qwfn645kyhn7-4 {
  list-style-type: none;
}
ol.lst-kix_99sc5y773xk6-4 {
  list-style-type: none;
}
ul.lst-kix_evjada3nolsj-3 {
  list-style-type: none;
}
ul.lst-kix_qwfn645kyhn7-3 {
  list-style-type: none;
}
ol.lst-kix_99sc5y773xk6-3 {
  list-style-type: none;
}
.lst-kix_evjada3nolsj-0 > li:before {
  content: '\0025cf  ';
}
.lst-kix_evjada3nolsj-4 > li:before {
  content: '\0025cb  ';
}
ul.lst-kix_qwfn645kyhn7-2 {
  list-style-type: none;
}
ul.lst-kix_qwfn645kyhn7-1 {
  list-style-type: none;
}
ul.lst-kix_evjada3nolsj-0 {
  list-style-type: none;
}
.lst-kix_evjada3nolsj-1 > li:before {
  content: '\0025cb  ';
}
.lst-kix_evjada3nolsj-5 > li:before {
  content: '\0025a0  ';
}
ul.lst-kix_qwfn645kyhn7-0 {
  list-style-type: none;
}
ol.lst-kix_99sc5y773xk6-8 {
  list-style-type: none;
}
ol.lst-kix_99sc5y773xk6-7 {
  list-style-type: none;
}
.lst-kix_99sc5y773xk6-8 > li {
  counter-increment: lst-ctn-kix_99sc5y773xk6-8;
}
ul.lst-kix_evjada3nolsj-6 {
  list-style-type: none;
}
ul.lst-kix_evjada3nolsj-5 {
  list-style-type: none;
}
.lst-kix_evjada3nolsj-3 > li:before {
  content: '\0025cf  ';
}
ul.lst-kix_evjada3nolsj-8 {
  list-style-type: none;
}
.lst-kix_99sc5y773xk6-3 > li {
  counter-increment: lst-ctn-kix_99sc5y773xk6-3;
}
ul.lst-kix_evjada3nolsj-7 {
  list-style-type: none;
}
.lst-kix_evjada3nolsj-8 > li:before {
  content: '\0025a0  ';
}
ol.lst-kix_99sc5y773xk6-3.start {
  counter-reset: lst-ctn-kix_99sc5y773xk6-3 0;
}
.lst-kix_evjada3nolsj-6 > li:before {
  content: '\0025cf  ';
}
.lst-kix_evjada3nolsj-7 > li:before {
  content: '\0025cb  ';
}
ol.lst-kix_99sc5y773xk6-0.start {
  counter-reset: lst-ctn-kix_99sc5y773xk6-0 0;
}
.lst-kix_99sc5y773xk6-7 > li {
  counter-increment: lst-ctn-kix_99sc5y773xk6-7;
}
ol.lst-kix_99sc5y773xk6-1.start {
  counter-reset: lst-ctn-kix_99sc5y773xk6-1 0;
}
li.li-bullet-0:before {
  margin-left: -21.3pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 21.3pt;
}
li.li-bullet-2:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
ol.lst-kix_99sc5y773xk6-4.start {
  counter-reset: lst-ctn-kix_99sc5y773xk6-4 0;
}
.lst-kix_99sc5y773xk6-2 > li {
  counter-increment: lst-ctn-kix_99sc5y773xk6-2;
}
.lst-kix_99sc5y773xk6-5 > li {
  counter-increment: lst-ctn-kix_99sc5y773xk6-5;
}
ol.lst-kix_99sc5y773xk6-2 {
  list-style-type: none;
}
ol.lst-kix_99sc5y773xk6-1 {
  list-style-type: none;
}
ol.lst-kix_99sc5y773xk6-0 {
  list-style-type: none;
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c14 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  /* font-family: 'Arial'; */
  font-style: normal;
}
.c2 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
  height: 11pt;
}
.c6 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  /* font-family: 'Arial'; */
  font-style: normal;
}
.c17 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c1 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  /* font-family: 'Arial'; */
  font-style: normal;
}
.c5 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
  line-height: 1.5;
}
.c9 {
  padding-top: 0pt;
  padding-bottom: 6pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c0 {
  text-decoration-skip-ink: none;
  font-size: 12pt;
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
}
.c8 {
  background-color: #ffffff;
  /* max-width: 468pt; */
  padding: 72pt 72pt 72pt 72pt;
}
.c12 {
  margin-left: 36pt;
  padding-left: 0pt;
}
.c13 {
  font-size: 12pt;
  font-weight: 700;
}
.c19 {
  color: inherit;
  text-decoration: inherit;
}
.c3 {
  padding: 0;
  margin: 0;
}
.c15 {
  font-size: 12pt;
}
.c18 {
  padding-left: 3.3pt;
}
.c4 {
  margin-left: -35.4pt;
}
.c11 {
  margin-left: -18pt;
}
.c16 {
  background-color: #ffffff;
}
.c10 {
  margin-left: 18pt;
}
.c7 {
  text-indent: -36pt;
}
.c20 {
  text-indent: -18pt;
}
.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  /* font-family: 'Arial'; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  /* font-family: 'Arial'; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  /* font-family: 'Arial'; */
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  /* font-family: 'Arial'; */
}
h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  /* font-family: 'Arial'; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  /* font-family: 'Arial'; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  /* font-family: 'Arial'; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  /* font-family: 'Arial'; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  /* font-family: 'Arial'; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  /* font-family: 'Arial'; */
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
